// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-how-it-works-index-js": () => import("./../../../src/pages/how-it-works/index.js" /* webpackChunkName: "component---src-pages-how-it-works-index-js" */),
  "component---src-pages-how-to-sell-index-js": () => import("./../../../src/pages/how-to-sell/index.js" /* webpackChunkName: "component---src-pages-how-to-sell-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-sell-in-bulk-index-js": () => import("./../../../src/pages/sell-in-bulk/index.js" /* webpackChunkName: "component---src-pages-sell-in-bulk-index-js" */),
  "component---src-pages-terms-and-conditions-index-js": () => import("./../../../src/pages/terms-and-conditions/index.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-js" */),
  "component---src-pages-thank-you-index-js": () => import("./../../../src/pages/thank-you/index.js" /* webpackChunkName: "component---src-pages-thank-you-index-js" */),
  "component---src-pages-track-order-index-js": () => import("./../../../src/pages/track-order/index.js" /* webpackChunkName: "component---src-pages-track-order-index-js" */)
}

